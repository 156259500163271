<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light shadow">
      <div class="container-fluid">
        <RouterLink class="navbar-brand" to="/">
          <img
            src="@/assets/givio-brand-logo.png"
            class="logo"
            alt="Givio Logo"
          />
        </RouterLink>

        <!-- Hamburger menu button (shown on smaller screens) -->
        <button
          class="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <!-- Use the Bootstrap bi-list icon for the hamburger menu -->
          <i class="bi bi-list toggler-icon"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <RouterLink class="nav-link" to="/">Home</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink class="nav-link" to="/gift-cards"
                >Gift Cards</RouterLink
              >
            </li>
            <li class="nav-item">
              <RouterLink class="nav-link" to="/customer-support"
                >Customer Support</RouterLink
              >
            </li>
            <li class="nav-item">
              <RouterLink class="nav-link" to="/frequently-asked-questions"
                >FAQ</RouterLink
              >
            </li>

            <!-- User Settings / Profile Section -->
            <li class="nav-item dropdown ms-auto">
              <template v-if="isLoggedIn">
                <!-- Show user icon when logged in -->
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-person-circle profile-icon"></i>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <RouterLink class="dropdown-item" to="/profile"
                      >My Profile</RouterLink
                    >
                  </li>
                  <li>
                    <RouterLink class="dropdown-item" to="/settings"
                      >Settings</RouterLink
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#" @click="handleLogout"
                      >Logout</a
                    >
                  </li>
                </ul>
              </template>

              <template v-else>
                <!-- Show login button when logged out -->
                <RouterLink class="btn btn-outline-primary ms-2" to="/login"
                  >Login</RouterLink
                >
              </template>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref } from "vue";

const isLoggedIn = ref(true); // Set to true if user is logged in, false otherwise

function handleLogout() {
  isLoggedIn.value = false; // Example logout logic
}
</script>

<style scoped>
/* Base styles for the navbar */
.navbar {
  color: white;
  background: black; /* Dark background */
  font-weight: bold;
  padding: 0.75rem 1rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Shadow to give a floating effect */
  display: flex;
  align-items: center; /* Ensure items align to the center vertically */
}

/* Add shadow when scrolling */
.navbar.scrolled {
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.router-link-exact-active {
  color: rgb(0, 149, 255) !important;
}

/* Logo styles */
.logo {
  height: 3.5rem; /* Increased size of the logo */
  width: auto;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.2); /* Slight zoom on hover */
}

/* Center and align navbar links */
.navbar-nav {
  margin-left: auto;
  display: flex;
  align-items: center; /* Align nav items vertically */
}

.nav-link {
  font-size: 1.1rem;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center; /* Center text vertically */
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link:hover {
  color: #f39c12; /* Bright yellow/gold on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

/* Profile icon styles */
.profile-icon {
  font-size: 1.75rem;
  color: white;
  transition: color 0.3s ease, box-shadow 0.3s ease;
}

.profile-icon:hover {
  color: #f39c12;
}

/* Button styling */
.btn-outline-primary {
  border-color: #f39c12;
  color: #f39c12;
}

.btn-outline-primary:hover {
  background-color: #f39c12;
  color: white;
}

/* Custom Toggler */
.navbar-toggler {
  border: none;
  outline: none;
}

.toggler-icon {
  font-size: 1.5rem;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo {
    height: 2.5rem; /* Slightly smaller logo for mobile view */
  }

  .nav-link {
    font-size: 0.9rem;
  }

  /* Adjust navbar padding on smaller screens */
  .navbar {
    padding: 0.5rem 0.75rem;
  }

  .btn-outline-primary {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 576px) {
  .logo {
    height: 2rem; /* Even smaller logo for extra small screens */
  }

  .nav-link {
    font-size: 0.85rem;
    padding: 0.4rem 0.6rem; /* Further reduce padding for smaller devices */
  }
}
</style>
